<template>
  <div class="container">
    <img src="https://img.chaolu.com.cn/ACT/training-record/middle-page.jpg" alt="">
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { getParam } from '@/lib/utils'
import { loginOut, upgrade } from '@/lib/appMethod'

export default {
  mixins: [userMixin],
  data() {
    return {
      mouldId: '',
      motionList: [],
    }
  },
  async created() {
    await this.$getAllInfo()
    if (!this.userId) {
      loginOut()
    }
  },

  methods: {


  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  background: #F5F5F5;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  img {
    width: 100%;
  }
}

</style>
