<template>
  <div v-if="isShow" id="wxOpenInAPPVui" class="wexin-launch-btn">
    <wx-open-launch-app :id="id" style="width: 100%; height: 100%; display: block" :appid="appid" :extinfo="extinfo">
      <script type="text/wxtag-template">
        <style>.btn {width:100%;height:65px;background: #fff}</style>
        <button class="btn">App内查看</button>
      </script>
    </wx-open-launch-app>
  </div>
</template>
<script>
import Vue from 'vue'
// 忽略自定义元素标签抛出的报错
Vue.config.ignoredElements = ['wx-open-launch-app']
let idIndex = 0
export default {
  name: 'wx-open-app',
  data() {
    idIndex++
    return {
      id: 'wxopenLanchAppId' + idIndex,
      appid: 'wxf001ee69e17eeda1' /* 跳转的AppID */,
      isShow: false,
    }
  },
  mounted() {
    this.init()
  },
  props: {
    extinfo: {
      type: Object,
      default: () => {},
    },
  },
  computed: {

  },
  methods: {
    init() {
      this.isShow = true
      this.$nextTick(() => {
        const _this = this
        const btn = document.getElementById(this.id)
        if (!btn) {
          return
        }
        btn.addEventListener('error', function () {
          window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.chaolu.app'
        })
      })
    },
  },
}
</script>
<style lang="less" scoped>
.wexin-launch-btn {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  overflow: hidden;
  z-index: 1;
}
</style>
