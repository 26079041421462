<template>
  <div class="container">
    <div class="main row-between-start flex-wrap">
      <div @click="jump(item)" class="item" v-for="item,index in motionList" :key="index">
        <img :src="item.image" alt="">
        <p class="f28">{{ item.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getParam, newAppBack } from '@/lib/utils'
import { appOpenWeb } from '@/lib/appMethod'
export default {
  data() {
    return {
      mouldId: '',
      motionList: [],
    }
  },
  async mounted() {
    this.mouldId = getParam().mouldId
    this.getMotionList()
  },

  methods: {
    newAppBack,
    getMotionList() {
      this.$axios.post(this.baseURLApp + '/scheduleLesson/lessonDetail/motionGroupDetail', {
        mouldId: this.mouldId
      }).then((res) => {
        this.motionList = res.data
      })
    },
    jump(item) {
      const { name, relateId, motionMode } = item
      appOpenWeb('', `${window.location.origin}/#/training-record/${ motionMode === 'MOTION' ? 'motion' : 'super-group' }?${ motionMode === 'MOTION' ? 'motionId' : 'groupId' }=${relateId}&title=${name}`)
    },

  },
}
</script>
<style lang="less" scoped>
*::-webkit-scrollbar {
  display: none;
}
.container {
  background: #F5F5F5;
  min-height: 100vh;
  * {
    box-sizing: border-box;
  }
  .main {
    padding: 32px;
    .item {
      width: 330px;
      height: 426px;
      background: #FFFFFF;
      border-radius: 12px 12px 12px 12px;
      margin: 0 0 28px;
      position: relative;
      padding: 16px;
      text-align: center;
      img {
        width: 100%;
        height: 298px;
        object-fit: contain;
      }
      p {
        margin: 12px 0 0;
      }
    }
  }
}
.grey {
  color: #6c727a;
}
</style>
