<template>
  <div class="page-rooter-fff">
    <headeBar title="aliplayer" left-arrow @click-left="newAppBack" />
    <div id="J_cole">
      <!-- <ali-player v-if="playauth" autoplay="true" :vid="vid" :playauth="playauth"></ali-player> -->
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import headeBar from '@/components/app/headBar'
import Aliplayer from 'aliyun-aliplayer'
import 'aliyun-aliplayer/build/skins/default/aliplayer-min.css'

export default {
  data() {
    return {
      vid: 'c04be23fa32471efb40c4531958d0102',
      playauth: '',
    }
  },
  mixins: [userMixin],
  components: { headeBar },
  async created() {
    initBack() //劫持后退
    await this.$getAllInfo()
  },
  async mounted() {
    await this.getAuth()

    var player = new Aliplayer(
      {
        id: 'J_cole',
        width: '100%',
        vid: this.vid, // 必选参数。音视频ID。示例：1e067a2831b641db90d570b6480f****。
        playauth: this.playauth, // 必选参数。音视频播放凭证。
        playConfig: {
          // 可选参数
          MtsHlsUriToken: '杰尼龟', // 可选参数，如需校验MtsHlsUriToken（用户令牌），可使用此参数传递。
          EncryptType: 'HLSEncryption', // 可选参数，设置加密类型为HLSEncryption时，则只会返回经过HLS标准加密的视频流。
        },
      },
      function (player) {
        console.log('The player is created.')
      }
    )
  },

  methods: {
    newAppBack,
    getAuth() {
      return this.$axios
        .post(`${this.baseURLApp}/th/ali/video/getPlayAuth`, {
          videoId: this.vid,
        })
        .then((res) => {
          this.playauth = res.data.playAuth
        })
    },
  },
}
</script>

<style lang="less" scoped>
.page-rooter-fff {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .page-content {
    //flex-grow: 1;
    flex: 1 1 0;
    overflow: hidden;
  }

  .com_headerbar {
    flex-shrink: 0;
  }
}
</style>
